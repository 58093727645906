import React from 'react'
import Question from './question'

import classes from '../styles/faq-component.module.sass'


export const Faq = ( {title, children, data}) => {

    return (
        <>
        <div>
            <div className={classes.container}>
                <div className={classes.faqbox}>
                    <h3 className={classes.title}><b>{title}</b></h3>
                    {children ? children.map((item, index) => (
                        <div>
                            <Question question={item.props.question} answer={item.props.children}></Question>
                        </div>
                    )) :    
                    <div>
                        {data.map((item, index) => (
                        <Question question={item.question} answer={item.answer}></Question>
                        ))}
                    </div>
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default Faq

