import React, {useEffect} from 'react'
import classes from '../styles/video.module.sass'


const Video = ( {toggleButtonComplete, url, updateData, didMount} ) => { 

    /*useEffect(() => {
        updateData([])
    // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete])*/
    

    useEffect(() => {
        if (didMount) {
            updateData([])
        }
        
    // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete]) 


    return ( 
        <div className={classes.videoContainer}>
            <iframe title="Video" frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen src={url}>
            </iframe>                  
        </div>
    );
};

export default Video 